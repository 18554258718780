<template>
  <div class="login-page">
    <!-- <nav-bar /> -->
    <b-row class="main-row">
        <!-- Brand logo-->
        <!-- <b-link class="brand-logo">
          <b-img style="width: 150px" :src="appDarkLogo" alt="logo" />
        </b-link> -->
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col sm="12" md="5" class="board-rightimg d-none d-md-flex align-items-center justify-content-center">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col sm="12" md="7" class="d-flex align-items-center auth-bg px-2 px-lg-5 bg-white justify-content-center">
          <div class="left-content text-center free-acc">
            <!-- <div class="text-center mb-2" v-if="data_local.company_logo">
              <b-img
                style="width: 245px"
                :src="data_local.company_logo"
                :alt="data_local.company_name"
              />
            </div> -->

            <b-card-title
              title-tag="h3"
              class="font-weight-bold mb-1">
              Welcome 
            </b-card-title>
            <!-- <b-card-title title-tag="h3" class="font-weight-bold mb-1" v-else>
              Welcome to Zircly! 👋
            </b-card-title> -->
            <b-card-text class="mb-2">
              To {{ data_local.company_name }}'s Zircly!
            </b-card-text>

            <!-- form -->
            <b-overlay :show="isLoading" rounded="sm" :variant="skin" class="mt-5">
              <validation-observer ref="loginValidation">
                <b-form autocomplete="off" class="auth-login-form mt-2" @submit.prevent>
                  <!-- email -->
                  <b-form-group>
                    <!-- <label class="required" for="login-email">Email</label> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="userEmail"
                          @keydown.space.prevent
                          :state="errors.length > 0 ? false : null"
                          name="login-email"
                          placeholder="email@mail.com"
                          @change="checkEmail()"
                        />

                        <b-input-group-append is-text>
                          <feather-icon icon="AtSignIcon" />
                        </b-input-group-append>
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <!-- <label class="required" for="login-password"
                        >Password</label
                      > -->
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- checkbox -->
                  <div class="d-flex align-items-center justify-content-between mb-50">
                    <b-form-group>
                      <b-form-checkbox
                        id="remember-me"
                        v-model="status"
                        name="checkbox-1"
                        class="no-select"
                      >
                        Remember Me
                      </b-form-checkbox>
                    </b-form-group>
                    <b-link class="mb-2 forgot-link"
                        :to="{
                          name: 'forgot-password',
                        }"
                      >
                        <small>Forgot Password?</small>
                      </b-link>
                  </div>
                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    @click="validationForm"
                    v-ripple.400
                    :disabled="isLoading"
                  >
                    Sign in
                    <!-- <span
                      class="spinner-border text-light spinner-border-sm ml-1"
                      v-if="isLoading"
                    ></span> -->
                  </b-button>
                </b-form>
              </validation-observer>
            </b-overlay>

            <!-- divider -->
            <div
              class="divider my-2"
              v-if="
                data_local.github_status == 'true' ||
                data_local.google_status == 'true' ||
                data_local.facebook_status == 'true' ||
                data_local.microsoft_status == 'true' ||
                data_local.yahoo_status == 'true'
              "
            >
              <div class="divider-text">Sign-in With</div>
            </div>
            

            <!-- social buttons -->
            <div class="auth-footer-btn d-flex flex-row justify-content-around other-login">
              <b-button
                v-ripple.400
                variant="light"
                v-if="data_local.microsoft_status == 'true'"
                class="border google-btn is-primary btn-login"
                href="javascript:void(0)"
                @click="SocialAuthProvider('microsoft')"
              >
                <b-img fluid :src="require('@/assets/images/icons/login/microsoft.svg')" width="20px" height="20px" class="before-hover" :alt="data_local.company_name" />
                <b-img fluid :src="require('@/assets/images/onboarding/microsoft.png')" width="30px" height="30px" class="after-hover" :alt="data_local.company_name" />
                <span>Microsoft</span>
              </b-button>

              <b-button
                v-ripple.400
                variant="light"
                v-if="data_local.facebook_status == 'true'"
                class="border google-btn is-primary btn-login"
                href="javascript:void(0)"
                @click="SocialAuthProvider('facebook')"
              >
                <b-img fluid :src="require('@/assets/images/icons/login/facebook.svg')" width="20px" height="20px" class="before-hover" :alt="data_local.company_name" />
                <b-img fluid :src="require('@/assets/images/onboarding/facebook.png')" width="23px" height="23px" class="after-hover" :alt="data_local.company_name" />
                <span>Facebook</span>
              </b-button>

              <b-button
                v-ripple.400
                variant="light"
                v-if="data_local.yahoo_status == 'true'"
                class="border google-btn is-primary btn-login"
                href="javascript:void(0)"
                @click="SocialAuthProvider('yahoo')"
              >
                <b-img fluid :src="require('@/assets/images/icons/login/yahoo.svg')" width="20px" height="20px" class="before-hover" :alt="data_local.company_name" />
                <b-img fluid :src="require('@/assets/images/onboarding/yahoo-new.png')" width="20px" height="20px" class="after-hover" :alt="data_local.company_name" />
                <span>Yahoo</span>
              </b-button>
              <b-button
                v-ripple.400
                variant="light"
                v-if="data_local.google_status == 'true'"
                class="border google-btn is-primary btn-login"
                href="javascript:void(0)"
                @click="SocialAuthProvider('google')"
              >
              <b-img fluid :src="require('@/assets/images/icons/login/google.svg')" width="20px" height="20px" class="before-hover" :alt="data_local.company_name" />
                <b-img fluid :src="require('@/assets/images/onboarding/google.png')" width="22px" height="22px" class="after-hover" :alt="data_local.company_name" />
                <span>Google</span>
              </b-button>
              <b-button
                variant="github"
                v-if="data_local.github_status == true"
                class="border google-btn is-primary btn-login"
                href="javascript:void(0)"
                @click="SocialAuthProvider('github')"
              >
                <feather-icon icon="GithubIcon" class="btngit-icon"/>
                <span>Github</span>
              </b-button>
            </div>
            <!-- divider -->
            <div class="my-2 find-org">
              <div>Not Your Organization ?<b-button v-ripple.400 variant="primary" class="shadow px-50" @click="findHost()">
                Find Your Organization
              </b-button> </div>
            </div>
          </div>
        </b-col>
        <!-- /Login-->
      </b-row>
    <!-- Footer -->
      <!-- <footer class="footer footer-light footer-static login-page-footer">
      <slot name="footer">
        <app-footer />
      </slot>
    </footer> -->
    <b-toast id="example-toast">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
            :src="require('@/assets/images/logo/logo.png')"
            class="mr-1"
            height="18"
            width="25"
            alt="Toast image"
          />
          <strong class="mr-auto">Vue Admin</strong>
          <small class="text-muted">11 mins ago</small>
        </div>
      </template>
      <span>Hello, world! This is a toast message. Hope you're doing well.. :)</span>
    </b-toast>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BToast
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Other imports
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import VueAxios from "vue-axios";
import VueSocialAuth from "vue-social-auth";
import Vue from "vue";
import { $themeConfig } from "@themeConfig";
import axios from "@axios";
const NavBar = () => import("@/components/NavBar.vue");
import AppFooter from "@core/layouts/components/AppFooter.vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.use(VueAxios, axios);
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);
import Ripple from 'vue-ripple-directive'
import { bus } from "@/main.js";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    NavBar,
    AppFooter,
    BToast,
    ToastificationContent
  },
  mixins: [togglePasswordVisibility],
  props: {
    code: String,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      status: "",
      skin: store.state.appConfig.layout.skin,
      password: "",
      userEmail: "",
      browser_agent:"",
      device_type:"",
      sideImg: require("@/assets/images/pages/login/login-image.svg"),
      // validation rules import store from '@/store/index'
      required,
      email,
      isLoading: false,
      data_local: {
        company_name: "",
        company_email: "",
        company_phone: "",
        company_website: "",
        company_address: "",
        company_logo: "",
      },
    };
  },

  created() {
    

    // Clear local storage and session storage
    localStorage.clear();
    sessionStorage.clear();
    console.log('Local and session storage cleared');

    if (window.location.search == "?redirected=true") {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: "Found Your Company Domain",
      //     icon: "EditIcon",
      //     variant: "success",
      //     text: "Redirected you to your company domain",
      //   },
      // });


      this.$toast({
              component: ToastificationContent,
              props: {
                title: "Found Your Company Domain",
                icon: 'EditIcon',
                variant: 'success',
                text: "Redirected you to your company domain",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
    }
    // if(!this.$cookies.get('TenantHost')){
    //   this.$router.push('/find-host').then(() => {
    //       this.$toast(
    //         {
    //           component: ToastificationContent,
    //           position: "top-right",
    //           props: {
    //             title: `Welcome Back`,
    //             icon: "CoffeeIcon",
    //             variant: "error",
    //             text: `Unable to find your organization, Plese enter your Official Email`,
    //           },
    //         }
    //       );
    //     })
    //     .catch((error) => {
    //       this.$refs.log.setErrors(error.response.data.error);
    //     });
    //   return false;
    // }else{
    //   axios.defaults.baseURL =  this.$cookies.get('TenantHost');
    // }
    if (
      window.location.hostname != "login.zircly.com" &&
      window.location.hostname != "127.0.0.1"
    ) {
      this.fetch_setting_data();
    }

    // Remove userData from localStorage
    //localStorage.removeItem("userData");
    // localStorage.clear()
    this.$cookies.remove("userData");

    if (this.$route.query.token) {
      useJwt.setToken(this.$route.query.token);
      useJwt.getUserInfo().then((res) => {
        this.AfterLogin(res);
      });
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    findHost() {
      window.location = "https://login.zircly.com/find-host";
      return false;
    },
    checkEmail() {
      if (
        window.location.hostname == "login.zircly.com" ||
        window.location.hostname == "127.0.0.1"
      ) {
        axios
          .get("/register/check-email/" + this.userEmail)
          .then((response) => {
            if (response.data.success) {
              window.location =
                "https://" +
                response.data.data.domain +
                "/login?redirected=true"; //http://192.168.0.191:8080/login

              return false;
            }
          });
      }
    },
    fetch_setting_data() {
      const self = this;
      axios
        .get("get-settings")
        .then((response) => {
          self.data_local = response.data.data.settings;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validationForm() {
  
      this.browser_agent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      this.device_type = deviceType;
      this.isLoading = true;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              browser_agent:this.browser_agent,
              device_type:this.device_type
            })
            .then((response) => {
              this.isLoading = true;
              useJwt.setToken(response.data.data.token);
              const birthday_celebrate = response.data.data.birthday_celebrate
              bus.$emit('celebrate-check', birthday_celebrate);
              useJwt.getUserInfo().then((res) => {
                this.AfterLogin(res);
              });
            })
            .catch((error) => {
              // Do something on error
              this.isLoading = false;
              if (error.response.status) {
                if (typeof error.response.data.errors !== "undefined") {
                  const keys = Object.keys(error.response.data.errors);
                  const values = Object.values(error.response.data.errors);
                  for (let i = 0; i <= keys.length; i += 1) {
                    if (
                      error.response.data.errors.hasOwnProperty.call(values, i)
                    ) {

                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Login Failed',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: values[0][0],
                      },
                    }, {
                      timeout: 2000, 
                      position: 'bottom-center',
                      toastClassName:"radius-8 error-info ",
                      hideProgressBar : true,
                    });
                    }
                  }
                }
              } else {
                this.isLoading = false;
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Login Failed",
                //     icon: "BellIcon",
                //     text: "Oops! Something Went Wrong",
                //     variant: "danger",
                //   },
                // });

                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Login Failed',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: "Oops! Something Went Wrong",
                      },
                    }, {
                      timeout: 2000, 
                      position: 'bottom-center',
                      toastClassName:"radius-8 error-info ",
                      hideProgressBar : true,
                    });
              }
            });
        } else {
          this.isLoading = false;
        }
      });
    },
    AfterLogin(response) {
      const userData = response.data.data;
      // localStorage.setItem("userData", JSON.stringify(userData));
      this.$cookies.set(
        "userData",
        userData,
        60 * process.env.VUE_APP_LOGIN_TIMEOUT
      ); // 60 minutes

      axios.get("abilities").then((response) => {
        this.$ability.update([{ subject: "all", actions: response.data.data }]);
        const encryptedText = this.CryptoJS.AES.encrypt(
                 JSON.stringify(response.data.data),
                    "ZirclyRocks"
                  ).toString();
        localStorage.setItem('EncryptedAbilities',encryptedText);
      });
      if (localStorage.getItem("hasRedirect")) {
        this.$router.push(localStorage.getItem("pathToLoadAfterLogin"));
      } else {
        this.$router
          .replace(getHomeRouteForLoggedInUser(userData.role))
          .then(() => {
            // this.$toast({
            //   component: ToastificationContent,
            //   position: "top-right",
            //   props: {
            //     title: `Welcome ${userData.name}`,
            //     icon: "CoffeeIcon",
            //     variant: "success",
            //     text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //   },
            // });
            this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Registration Completed',
          icon:'EditIcon',
          variant : 'success',
          hideClose: true,
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      })
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: `Secret Santa Spectacle: Unwrap the Festive Fun! 🎁🎅`,
            //     icon: "GiftIcon",
            //     variant: "danger",
            //     text: `🎉 Unwrap the joy in our Secret Santa Game! 🎅✨ Join the merry mayhem and discover the excitement! 🎁🕵️‍♂️ #SecretSantaFun 🌟`,
            //   },
            // });
          })
          .catch((error) => {
            console.log(error);
            // this.$refs.log.setErrors(error.response.data.error);
          });
      }
    },
    SocialAuthProvider(provider) {
      const self = this;
      let data = {};
      data.url = "authorize/" + provider + "/redirect";
      // data.params = {
      //   is_mobile : true,
      // };
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          if(res.data.url.search("state") == -1){
            window.location.href = res.data.url+'&state='+window.location.hostname;
            
          }else{
            window.location.href = res.data.url;//+'&state='+window.location.hostname;
          }
        })
        .catch((err) => {
          console.log({ err: err });
        });
      return;
      this.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {
          console.log({ err: err });
        });

      window.location.href =
        process.env.VUE_APP_ASSET_URL + "/login/" + provider;
      return;
      self.$auth
        .authenticate(provider)
        .then((response) => {
          self.SocialLogin(provider, response);
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Login Failed",
              icon: "BellIcon",
              variant: "danger",
              text: "Please try again after sometime ",
            },
          });
        });
    },

    SocialLogin(provider, response) {
      const self = this;
      useJwt
        .socialLogin({
          provider,
          response,
        })
        .then((res) => {
          useJwt.setToken(res.data.data.token);
          useJwt.getUserInfo().then((userRes) => {
            self.AfterLogin(userRes);
          });
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   props: {
          //     title: "Login Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: "Please try again after sometime",
          //   },
          // });

          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Login Failed',
          icon:'BellIcon',
          variant : 'danger',
          hideClose: true,
          text: "Please try again after sometime"
          
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"radius-8 error-info",
        hideProgressBar : true,
      })
        });
    },
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}

footer.login-page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #f8f8f8;
  z-index: 999;
}
</style>
